<template>
  <loading-dialog :loading="$apollo.loading">
    <v-container class="pa-3" fluid>
      <v-row no-gutters>
        <v-col cols="auto">
          <h2 class="pa-2"><v-icon class="mr-2 mb-1">mdi-view-dashboard</v-icon> Dashboard:</h2>
        </v-col>
      </v-row>
      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeDLEENPcL--J_2lzBOen0vQF0TaeIioLiDGNf8ccy_LvqMuA/viewform">Para sugestões e melhorias</a>
        </v-col>
      </v-row>
      <home-dashboard />
      <!-- <h2 class="pa-2"><v-icon class="mr-2 mb-1">mdi-alert</v-icon> Incidentes:</h2>
      <incidents-panel /> -->
      <h2 class="pa-2"><v-icon class="mr-2 mb-1">mdi-chart-bar</v-icon> Visão Geral:</h2>
      <v-row>
        <v-col cols="6">
          <accounts-by-status v-if="adminAccounts" :accounts="adminAccounts" />
        </v-col>
        <v-col cols="6">
          <accounts-by-drift v-if="adminAccounts" :accounts="adminAccounts" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <accounts-by-situation v-if="adminAccounts" :accounts="adminAccounts" />
        </v-col>
        <v-col cols="6">
          <accounts-by-integration v-if="adminAccounts" :accounts="adminAccounts" />
        </v-col>
      </v-row>
    </v-container>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNTS } from '@/modules/home/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    AccountsByStatus: () => import('@/modules/home/PlotAccountsByStatus.vue'),
    AccountsByDrift: () => import('@/modules/home/PlotAccountsByDrift.vue'),
    AccountsBySituation: () => import('@/modules/home/PlotAccountsBySituation.vue'),
    AccountsByIntegration: () => import('@/modules/home/PlotAccountsByIntegration.vue'),
    HomeDashboard: () => import('@/modules/home/components/HomeDashboard')
    // IncidentsPanel: () => import('@/modules/home/components/IncidentsPanel')
  },
  apollo: {
    adminAccounts: {
      query: QUERY_GET_ACCOUNTS,
      fetchPolicy: 'network-only',
      error(err) {
        console.log('failed.to.get.account.or.user.not.authenticated', err.message)
      }
    }
  }
}
</script>
