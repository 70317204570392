import gql from 'graphql-tag'

export const QUERY_GET_ACCOUNTS = gql`
  {
    adminAccounts(active: true, franchise: false) {
      status_reporter {
        drift
        status
      }
      status_account {
        deploying
        done
        impossible_support
        test
        churn
        temporary_churn
        adjusting
        deprecated
      }
      integration_type
    }
  }
`
export const QUERY_QUICK_ACCESS = gql`
  query($param: String, $integration_type: [String]) {
    quickAccess(param: $param, integration_type: $integration_type) {
      _id
      db_name
    }
  }
`
export const QUERY_GET_INCIDENTS = gql`
  query {
    incidents {
      _id
      context
      sub_context
      db_name
      error
      error_traceback
      status
      created_at
      account_id
    }
  }
`
